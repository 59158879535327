import React from 'react';
import PageGenerator from 'components/page-generator';
import BlogPostWrapper from 'components/blog-post-wrapper';
import Loading from 'shared/loading';
import useWordpress from 'hooks/useWordpress';
import 'styles/project.scss';

export default function PostTemplate({ pageContext }) {
  const context = process.env.GATSBY_DEVELOPMENT
    ? useWordpress(pageContext)
    : pageContext;
  return context ? (
    <BlogPostWrapper {...context}>
      <PageGenerator {...context} />
    </BlogPostWrapper>
  ) : (
    <Loading />
  );
}
