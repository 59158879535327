import React from 'react';
import Container from 'container';
import Img from 'img';
import Headline from 'headline';
import getTag from 'utils/get-tag';
import Button from 'button';
import CustomHeadlineA from 'shared/section-headlines/custom-headline-a';
import s from './cta-ad.module.scss';

export default function CtaAd({ title, text, image, button, className, ...props }) {
  const TextTag = getTag(text);
  return (
    <div className={`${className || ''} ${s.root}`}>
      <div className={s.imgWrapper}>
        <Img className={s.img} src={image} alt={image} />
      </div>
      <div className={s.infoWrapper}>
        <CustomHeadlineA
          className={s.headlineWrapper}
          titleClassName={s.ctaTtile}
          subtitleClassName={s.ctaSubtitle}
          title={title.split(' ')[0]}
          subtitle={title
            .split(' ')
            .slice(1)
            .join(' ')}
          {...props}
        />
        <TextTag
          className={s.text}
          dangerouslySetInnerHTML={{ __html: text }}
        />
        <div className={s.buttonWrapper}>
          <Button className={s.button} tertiary href={button.href}>
            {button.text}
          </Button>
        </div>
      </div>
    </div>
  );
}
