import React from 'react';
import Container from 'container';
import Headline from 'headline';
import Button from 'button';
import Img from 'img';
import s from './blog_post.module.scss';

export default function BlogPost({
  headline,
  subtitle1,
  subtitle2,
  paragraph,
  button,
  image
}) {
  return (
    <Container className={s.section}>
      <div className={s.wrapper}>
        <Img className={s.image} src={image?.title} />
        <div className={s.content}>
          <div className={s.titleWrapper}>
            <Headline
              className={s.headline}
              h2
              wild
              dangerouslySetInnerHTML={{ __html: headline }}
            />
            <Headline
              className={s.underline}
              h2
              wildExtra
              white
              center
              html="D"
            />
          </div>
          <p
            className={s.paragraph}
            dangerouslySetInnerHTML={{ __html: paragraph }}
          />
        </div>
        {subtitle1 && (
          <Headline
            className={s.subtitle1}
            dangerouslySetInnerHTML={{ __html: subtitle1 }}
          />
        )}
        {subtitle2 && <Headline className={s.subtitle2} html={subtitle2} />}
        <Button className={s.button} tertiary href={button.href}>
          {button.text}
        </Button>
      </div>
    </Container>
  );
}
