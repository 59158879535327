import React from 'react';
import Img from 'img';
import getDate from 'utils/get-date';
import Headline from 'headline';
import Blog from 'components/page-templates/blog';
import BlogPostCard from 'shared/cards/post-card';
import PostAside from 'shared/cards/post-aside';
import { Link } from 'gatsby';
import CTAAd from './cta-ad';

import s from './blog-post-wrapper.module.scss';

export default function BlogPostWrapper({ children, popularPosts, ...props }) {
  // const ctaInfo = {
  //   title: "Hi! I'M LAURIE-ANNE",
  //   image: 'post-cta',
  //   text:
  //     '<strong>You deserve to live the life of your dreams.</strong> It’s my job to help you get there!',
  //   button: {
  //     text: 'read more',
  //     href: '/'
  //   }
  // };
  const postCategories = props.categories
    ? props.categories.filter(
      cat => props.postCategories && props.postCategories.includes(cat.id)
    )
    : [];
  const categoriesArr = postCategories.map(cat => cat.id);
  const relatedPosts = popularPosts
    .reverse()
    .filter(post => (post.postCategories ? (post.postCategories.reduce(
      (acc, curr, i) => categoriesArr.includes(curr) + acc,
      false
    )) : []))
    .filter(post => post.id !== props.id);
  const featuredPost = relatedPosts[relatedPosts.length - 1];
  // const mainCategory = postCategories[0] || null;
  const mainTitle = props.title && props.title.rendered;
  const titleSlicePoint = props?.title?.rendered?.match(' ')?.index >= 11 ? 1 : 2;
  const mainSubtitle = props?.subtitle
    ? props.subtitle.split(' ')
    : null;
  const parsedAds = props.ad_templates !== undefined && props.ad_templates
    ? props.ad_templates.reduce((acc, curr) => ({
      [curr.acf_fc_layout]: {
        ...curr
      },
      ...acc
    }), {})
    : null;
  const ctaInfo = props.ad_templates !== undefined && props.ad_templates
    ? {
      title: parsedAds.cta.title,
      text: parsedAds.cta.text,
      image: 'post-cta',
      button: parsedAds.cta.button
    }
    : null;
  const cardInfo = props.ad_templates !== undefined && props.ad_templates ? parsedAds.card : null;
  return (
    <main className={s.root}>
      <div className={s.hero}>
        <div className="container">
          <div className={s.cardWrapper}>
            <div className={s.heroImageWrapper}>
              <Img
                className={s.heroImage}
                src={
                  (props.mainImage && props.mainimage?.title) || 'generalhero'
                }
              />
            </div>
            <div className={s.overlay} />
            <div className={s.titleWrapper}>
              {mainTitle && (
                <>
                  <Headline
                    h1
                    el="h6"
                    html={mainTitle
                      .split(' ')
                      .slice(0, titleSlicePoint)
                      .join(' ')}
                    primary
                    wild
                    className={s.headline}
                  />
                  <Headline
                    h1
                    el="h6"
                    html={mainTitle
                      .split(' ')
                      .slice(titleSlicePoint)
                      .join(' ')}
                    primary
                    wild
                    className={`${s.headline} ${s.line2}`}
                  />
                  <Headline
                    h1
                    el="h6"
                    html="D"
                    white
                    center
                    wildExtra
                    className={s.underline}
                  />
                </>
              )}
              {mainSubtitle && (
                <h2 className={s.title}>
                  <span>
                    {mainSubtitle
                      .slice(0, Math.floor(mainSubtitle.length / 2))
                      .join(' ')}
                  </span>
                  <br />
                  <span>
                    {mainSubtitle
                      .slice(Math.floor(mainSubtitle.length / 2))
                      .join(' ')}
                  </span>
                </h2>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className={s.wrapper}>
          {ctaInfo && <CTAAd {...ctaInfo} className={s.hideDesktop} />}
          <section className={s.content}>
            <div className={s.metaInfo}>
              <Headline h1 className={s.h1} html={mainTitle} />
              <p className={`text-body-light ${s.subtitle}`}>
                <span>&#35;</span>
                <span>
                  {postCategories
                    && postCategories.map(({ name }, i) => {
                      const isLast = i === postCategories.length - 1;
                      return `${name}${!isLast ? ',' : ''} `.replace(/_/g, ' ');
                    })}
                </span>
              </p>
            </div>
            {children}
          </section>
          <aside className={s.sideBar}>
            <div className={s.scrollable}>
              {ctaInfo && <CTAAd {...ctaInfo} className={s.hideMobile} />}
              <div className={`${s.sideSection}`}>
                <Headline h2 wild primary className={s.sideTitle} el="h4">
                  Related Articles
                </Headline>
                <ul className={s.sideList}>
                  {relatedPosts.slice(0, 3).map(post => (
                    <li key={post.title.rendered} className={s.listItem}>
                      <Link className={s.sideLink} to={`/${post.slug}`}>
                        {/* <span className={s.popularDate}>
                          {getDate(props.date)}
                        </span> */}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: post.title.rendered
                          }}
                        />
                      </Link>
                    </li>
                  ))}
                </ul>
                <div className={`${s.sideSection}`}>
                  {cardInfo && (
                    <PostAside
                      headline={
                        cardInfo.title
                        && `${cardInfo.title
                          .split(' ')
                          .slice(0, 3)
                          .join(' ')
                        }<br/>${
                          cardInfo.title
                            .split(' ')
                            .slice(3)
                            .join(' ')}`
                      }
                      subtitle1={
                        cardInfo.subtitle
                        && cardInfo.subtitle
                          .split(' ')
                          .slice(0, 2)
                          .join(' ')
                      }
                      subtitle2={
                        cardInfo.subtitle
                        && cardInfo.subtitle
                          .split(' ')
                          .slice(2)
                          .join(' ')
                      }
                      paragraph={
                        cardInfo.excerpt
                        || 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
                      }
                      button={cardInfo.button}
                      image={cardInfo.image}
                      secondary
                    />
                  )}
                </div>
              </div>
              {/* <div className={`${s.sideSection}`}> */}
              {/* <Headline h2 wild tertiary className={s.sideTitle} el="h4">
                Blog Categories
              </Headline>
              <ul className={s.sideList}>
                {props.categories.map(({ name, slug }) => (
                  <li key={slug} className={s.listItem}>
                    <Link
                      className={s.sideLink}
                      to={`/blog/categories/${slug}`}
                    >
                      {name}
                    </Link>
                  </li>
                ))}
              </ul> */}
              {/* </div> */}
            </div>
          </aside>
        </div>
      </div>
      <div className={`${s.banner} ${s.bottom}`} />
      {cardInfo && (
        <BlogPostCard
          headline={
            cardInfo.title
            && `${cardInfo.title
              .split(' ')
              .slice(0, 3)
              .join(' ')
            }<br/>${
              cardInfo.title
                .split(' ')
                .slice(3)
                .join(' ')}`
          }
          subtitle1={
            cardInfo.subtitle
            && cardInfo.subtitle
              .split(' ')
              .slice(0, 2)
              .join(' ')
          }
          subtitle2={
            cardInfo.subtitle
            && cardInfo.subtitle
              .split(' ')
              .slice(2)
              .join(' ')
          }
          paragraph={
            cardInfo.excerpt
            || 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
          }
          button={{ text: 'READ MORE', href: cardInfo.slug }}
          image={cardInfo.image}
        />
      )}
      <Blog
        headline="Keep Reading"
        subtitle="RELATED ARTICLES"
        posts={relatedPosts.slice(0, 3).map(post => ({
          image: post.mainImage,
          title: post.title && post.title.rendered,
          button: { button_text: 'READ MORE', href: post.slug },
          text: post.excerpt
        }))}
      />
    </main>
  );
}
